<script setup>
import Logo from "@/components/base/Logo.vue";
import {useRouter} from "vue-router";
import {useNavigationStore} from "@/store/nav";

const props = defineProps({
  isOpen: Boolean
});
const emit = defineEmits(['close']);

const router = useRouter();
const navStore = useNavigationStore();

const setBlock = (name) => {
  if(router.currentRoute.value.name !== "home") {
    router.push({ path: "/" });
  }
  navStore.setBlockClicked(true);
  navStore.setBlockIndex(name);
  emit("close");

  setTimeout(() => {
    navStore.setBlockClicked(false);
    navStore.setBlockIndex("");
  }, 500);
}
</script>

<template>
  <Transition name="mob-menu">
    <div v-if="isOpen" class="menu flex flex-col">
      <div class="menu__top flex items-center justify-between">
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <div @click="$emit('close')" class="cross">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M0.957566 20.7324C0.358729 21.3312 0.330213 22.4004 0.971824 23.0277C1.59918 23.655 2.66853 23.6408 3.26737 23.042L11.9933 14.3025L20.7335 23.042C21.3465 23.655 22.4016 23.655 23.029 23.0277C23.6421 22.3862 23.6564 21.3454 23.029 20.7324L14.3031 11.9929L23.029 3.26764C23.6564 2.65459 23.6564 1.59958 23.029 0.972276C22.3874 0.359228 21.3465 0.344971 20.7335 0.958019L11.9933 9.69751L3.26737 0.958019C2.66853 0.359228 1.58492 0.330715 0.971824 0.972276C0.344471 1.59958 0.358729 2.66885 0.957566 3.26764L9.69774 11.9929L0.957566 20.7324Z" fill="white" fill-opacity="0.5"/>
          </svg>
        </div>
      </div>
      <div class="menu__content flex flex-col">
        <p @click="setBlock('about')">About</p>
        <p @click="setBlock('choose')">Why Choose Us?</p>
        <p @click="setBlock('benefits')">Benefits</p>
      </div>
      <p class="bottom">©2024 GERNE Technology SARL. All Rights Reserved</p>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: linear-gradient(180deg, #1059D1 -79.91%, #0B3583 100%), #D9D9D9;
  padding: 30px 20px 26px;
  &__content {
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
    line-height: 44px;
    gap: 32px;
    margin-top: 138px;
    p {
      transition: all .4s ease;
      cursor: pointer;
      &:hover, &:active {
        color: #C6F608;
      }
    }
  }
  .bottom {
    color: #FFF;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
    opacity: 0.4;
    margin-top: auto;
  }
  .cross svg path {
    transition: all .4s ease;
  }
  .cross:hover, .cross:active {
    cursor: pointer;
    svg path {
      fill-opacity: 1;
    }
  }
}

.mob-menu-enter-active,
.mob-menu-leave-active {
  transition: transform 0.5s ease;
}

.mob-menu-enter-from,
.mob-menu-leave-to {
  transform: translateX(100%);
}

.mob-menu-enter-to,
.mob-menu-leave-from {
  transform: translateX(0);
}

</style>