<template>
  <section class="about">
    <div class="container">
      <div class="about-content" ref="about">
        <p
          class="title"
          v-motion
          :initial="{ y: 20 }"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 0 }}"
        >About CRM Module</p>
        <div class="body flex justify-between">
          <div class="body-left">
            <p
              class="text"
              v-motion
              :initial="{ y: 15 }"
              :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 50 }}"
            >State-of-the-art tool designed to revolutionize your communication campaigns</p>
            <div
                v-motion
                :initial="{ y: 10 }"
                :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 0 }}"
            >
              <p>Our software is built to help businesses create, manage, and optimize marketing activities aimed at both retaining and attracting users. </p>
              <p>With advanced features like A/B testing, customer journey mapping, and real-time feedback collection, the CRM Module ensures your campaigns are not only effective but also scalable from local to global reach.</p>
            </div>
          </div>
          <div class="body-right">
            <img
              src="@/assets/img/about.png"
              alt="crm about block picture"
              v-motion
              :initial="{ scale: 0.9, opacity: 0}"
              :visibleOnce="{ scale: 1, opacity: 1, transition: { duration: 1300, delay: 0 }}"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.about {
  padding: 130px 0 0;
  .title {
    color: #124295;
    font-feature-settings: 'salt' on;
    font-size: 60px;
    font-weight: 600;
    line-height: 124px;
  }
  .body {
    margin-top: 28px;
    &-left {
      width: 100%;
      max-width: 650px;
      .text {
        color: #124295;
        font-feature-settings: 'salt' on;
        font-size: 34px;
        font-weight: 400;
        line-height: 43px;
      }
      & > div p {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-transform: capitalize;
        margin-top: 27px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .about {
    .body {
      gap: 30px;
      &-right {
        width: 45%;
        img {
          min-width: 340px;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .about {
    .body {
      flex-direction: column;
    }
  }
}

@media (max-width: 700px) {
  .about {
    position: relative;
    z-index: 3;
    padding: 60px 0 0;
    .body {
      flex-direction: column;
      &-left {
        .text {
          font-size: 26px;
          font-weight: 400;
          line-height: 41px;
        }
        & > div p{
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
        }
      }
      &-right {
        width: 100%;
        img {
          min-width: 0;
        }
      }
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
  }
}
</style>