<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import {useNavigationStore} from "@/store/nav";

const navs = ref([
  {
    label: "About",
    block: "about"
  },
  {
    label: "Why Choose Us?",
    block: "choose"
  },
  {
    label: "Benefits",
    block: "benefits"
  }
])
const router = useRouter();
const navStore = useNavigationStore();

const setBlock = (name) => {
  if(router.currentRoute.value.name !== "home") {
    router.push({ path: "/" });
  }
  navStore.setBlockClicked(true);
  navStore.setBlockIndex(name);

  setTimeout(() => {
    navStore.setBlockClicked(false);
    navStore.setBlockIndex("");
  }, 500);
}
</script>

<template>
  <nav class="flex items-center nav">
    <p
      v-for="(nav, index) in navs"
      :key="index"
      @click="setBlock(nav.block)"
      class="nav-item"
    >
      {{ nav.label }}
    </p>
  </nav>
</template>

<style scoped lang="scss">
.nav {
  gap: 46px;
  &-item {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    transition: all .3s ease;
    cursor: pointer;
    &:hover, &:active {
      color: #C6F608;
    }
  }
}

@media (max-width: 900px) {
  .nav {
    gap: 12px;
  }
}

@media (max-width: 700px) {
  .nav {
    display: none;
  }
}
</style>