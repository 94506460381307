<template>
  <div class="home">
    <Header/>
    <Banner />
    <About ref="about"/>
    <WhyChooseUs ref="choose" />
    <Benefits ref="benefits"/>
    <Footer ref="contacts" />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, nextTick } from "vue";
import Header from "@/components/base/Header.vue";
import Banner from "@/components/sections/Banner.vue";
import About from "@/components/sections/About.vue";
import WhyChooseUs from "@/components/sections/WhyChooseUs.vue";
import Benefits from "@/components/sections/Benefits.vue";
import Footer from "@/components/base/Footer.vue";
import {useNavigationStore} from "@/store/nav";

const navigationStore = useNavigationStore();
const about = ref(null);
const choose = ref(null);
const benefits = ref(null);
const contacts = ref(null);

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  if (navigationStore.blockClicked) {
    nextTick(() => {
      scrollToBlock(newValue);
    });
  }
}, {deep: true})

const getBlockRef = (index) => {
  switch(index) {
    case 'about':
      return about.value;
    case 'choose':
      return choose.value;
    case 'benefits':
      return benefits.value;
    case 'contacts':
      return contacts.value;
  }
}

const scrollToBlock = (blockIndex) => {
  const blockRef = getBlockRef(blockIndex);
  if (blockRef && blockRef.$el) {
    blockRef.$el.scrollIntoView({ behavior: 'smooth' });
  }
}

onMounted(() => {
  if (navigationStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(navigationStore.blockIndex)
    }, 0);
  }
})

onBeforeUnmount(() => {
  navigationStore.setBlockIndex("");
  navigationStore.setBlockClicked(false);
})

</script>
