<script setup>

</script>

<template>
  <section class="banner">
    <img class="bg" src="@/assets/img/bg.jpg" alt="crm background" />
    <div class="container">
      <div class="banner-content">
        <p
          class="big"
          v-motion
          :initial="{ y: 20 }"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 0 }}"
        >Welcome to the CRM Module</p>
        <p
          class="little"
          v-motion
          :initial="{ y: 20 }"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500, delay: 50 }}"
        >by GERNE Technology</p>
        <div
          class="dashboard"
          v-motion
          :initial="{ opacity: 0, scale: 0.9 }"
          :visibleOnce="{opacity: 1, scale: 1, transition: { duration: 1400, delay: 50 }}"
        >
          <img
            src="@/assets/img/dashboard.jpg"
            alt="crm dashboard"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.banner {
  position: relative;
  padding: 150px 0 0;
  img.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &-content {
    position: relative;
    z-index: 2;
  }
  .big {
    color: #124295;
    text-align: center;
    font-feature-settings: 'salt' on;
    font-size: 124px;
    font-weight: 700;
    line-height: 124px;
  }
  .little {
    color: #000;
    text-align: center;
    font-feature-settings: 'salt' on;
    font-size: 38px;
    font-weight: 400;
    line-height: 124px;
    margin-top: -7px;
  }
  .dashboard {
    border-radius: 22px;
    overflow: hidden;
    position: relative;
    z-index: 3;
    box-shadow: 0px 4px 69.4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 65px;
    img {
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

@media (max-width: 1200px) {
  .banner {
    .big {
      font-size: 90px;
      line-height: 90px;
    }
    .little {
      font-size: 24px;
      line-height: 90px;
    }
  }
}

@media (max-width: 900px) {
  .banner {
    .big {
      font-size: 75px;
      line-height: 75px;
    }
    .little {
      font-size: 20px;
      line-height: 75px;
    }
  }
}

@media (max-width: 700px) {
  .banner {
    padding: 120px 0 0;
    position: static;
    img.bg {
      max-height: 1000px;
    }
    .big {
      font-size: 38px;
      line-height: 48px;
      max-width: 350px;
      margin: 0 auto 12px;
    }
    .little {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }
    .dashboard {
      margin-top: 60px;
      border-radius: 8px;
    }
  }
}
</style>