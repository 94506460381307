<script setup>

</script>

<template>
  <button class="flex justify-center items-center">
    <p><slot></slot></p>
  </button>
</template>

<style scoped lang="scss">
button {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 52px;
  background: #C6F608;
  width: 177px;
  height: 44px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    opacity: 0.8;
  }
}

@media (max-width: 700px) {
  button {
    width: 120px;
    height: 40px;
    color: #000;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>