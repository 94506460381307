<script setup>
import { ref } from "vue";
import {useRouter} from "vue-router";
import Logo from "@/components/base/Logo.vue";
import Nav from "@/components/base/Nav.vue";
import Button from "@/components/ui/Button.vue";
import MenuButton from "@/components/additional/MenuButton.vue";
import Menu from "@/components/additional/Menu.vue";
import {useNavigationStore} from "@/store/nav";

const isMenu = ref(false);

const router = useRouter();
const navStore = useNavigationStore();

const setBlock = (name) => {
  if(router.currentRoute.value.name !== "home") {
    router.push({ path: "/" });
  }
  navStore.setBlockClicked(true);
  navStore.setBlockIndex(name);

  setTimeout(() => {
    navStore.setBlockClicked(false);
    navStore.setBlockIndex("");
  }, 500);
}
</script>

<template>
  <header class="header">
    <div class="container">
      <div class="header-content flex items-center justify-between">
        <div class="header-left flex items-center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Nav />
        </div>
        <div class="action flex items-center justify-between">
          <Button @click="setBlock('contacts')">Let’s start</Button>
          <MenuButton @click="isMenu = true"/>
        </div>
      </div>
    </div>
  </header>
  <Menu :isOpen="isMenu" @close="isMenu = false" />
</template>

<style lang="scss">
.header {
  background: linear-gradient(180deg, #1059D1 -79.91%, #0B3583 100%);
  backdrop-filter: blur(6.5px);
  height: 104px;
  position: relative;
  z-index: 2;
  a {
    text-decoration: none;
  }
  .container {
    height: 100%;
  }
  &-content {
    height: 100%;
  }
  &-left {
    gap: 70px;
  }
  .menu-button {
    display: none;
  }
}

@media (max-width: 900px) {
  .header {
    &-left {
      gap: 20px;
    }
  }
}

@media (max-width: 700px) {
  .header {
    .menu-button {
      display: flex;
    }
    .action {
      gap: 16px;
    }
  }
}
</style>