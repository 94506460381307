<script setup>
import { ref } from "vue";

const items = ref([
  {
    title: "Innovation",
    shadow: "Innovation",
    text: "We are dedicated to incorporating the latest technological advancements into our software, ensuring you have the most powerful tools at your disposal.",
    active: false
  },
  {
    title: "Customer-Centric Approach",
    shadow: "CCA",
    active: false,
    text: "Our primary focus is on helping you succeed. We design our features with your needs in mind, ensuring they are both practical and effective."
  },
  {
    title: "Scalability",
    shadow: "Scalability",
    active: false,
    text: "Whether you're a small business or a large enterprise, our CRM Module can handle campaigns of any magnitude, making it a versatile choice for any organization."
  },
  {
    title: "Support",
    shadow: "Support",
    active: false,
    text: "As we grow, we are committed to providing exceptional customer support. We ready to assist you with any questions or issues you may encounter."
  }
])
</script>

<template>
  <div class="accordion">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="item"
      :class="{'active': item.active}"
      @click="item.active = !item.active"
    >
      <div class="top flex items-center justify-between">
        <p class="title">{{ item.title }}</p>
        <div class="arrow flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="31" height="18" viewBox="0 0 31 18" fill="none">
            <path d="M27.5625 3.28125L15.9883 14.8555C15.5978 15.246 14.9646 15.246 14.5741 14.8555L2.99984 3.28125" stroke="#01386E" stroke-opacity="0.54" stroke-width="5" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
      <div class="hidden" :class="{'show': item.active}">
        <p class="shadow">{{ item.shadow }}</p>
        <p class="text">{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.accordion {
  margin-top: 50px;
  .item {
    position: relative;
    padding: 35px 0;
    height: 66px;
    border-bottom: 1px solid rgba(16, 65, 149, 0.37);
    overflow: hidden;
    .top {
      transform: translateY(-6px);
      cursor: pointer;
    }
    .arrow {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      transition: all .4s ease;
      svg {
        height: 18px;
        transition: all .3s ease;
        path {
          transition: all .3s ease;
        }
      }
    }
    &.active {
      height: 100%;
      .arrow {
        background: #C6F609;
        svg {
          transform: rotate(180deg);
          path {
            stroke-opacity: 1;
          }
        }
      }
      .shadow {
        opacity: 1;
      }
    }
    &:first-child {
      &.active {
        border-top: 1px solid rgba(16, 65, 149, 0.37);
      }
    }
    .hidden {
      overflow: hidden;
      max-height: 0;
      transition: height .5s ease;
      &.show {
        max-height: none !important;
      }
    }
    .title {
      color: #124295;
      font-feature-settings: 'salt' on;
      font-size: 34px;
      font-weight: 600;
      line-height: 43px;
    }
    .shadow {
      position: absolute;
      left: 23px;
      top: 54px;
      color: rgba(18, 66, 149, 0.04);
      font-feature-settings: 'salt' on;
      font-size: 71px;
      font-weight: 600;
      line-height: 43px;
      opacity: 0;
      transition: all .3s ease;
    }
    .text {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      width: 100%;
      max-width: 630px;
      margin: 20px 10% 48px auto;
    }
  }
}

@media (max-width: 700px) {
  .accordion {
    margin-top: 40px;
    .item {
      padding: 3px 17px;
      height: 70px;
      &:nth-child(2) {
        .top {
          transform: translateY(45px);
        }
        .arrow {
          transform: translateY(-16px);
        }
      }
      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        max-width: 300px;
      }
      .text {
        font-size: 16px;
        line-height: 30px;
      }
      .arrow {
        width: 52px;
        height: 52px;
      }
      .top {
        transform: translateY(35px);
        position: relative;
        z-index: 3;
      }
      .shadow {
        color: #F6F7FB;
        font-size: 22px;
        line-height: 32px;
        left: 30px;
        top: 66px;
      }
      .text {
        font-size: 16px;
        line-height: 30px;
        margin: 60px 0 40px;
      }
      &:first-child {
        &.active {
          border-top: none;
        }
      }
    }
  }
}
</style>