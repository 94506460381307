<script setup>
import Logo from "@/components/base/Logo.vue";
import GoTop from "@/components/additional/GoTop.vue";
import {useNavigationStore} from "@/store/nav";
import {useRouter} from "vue-router";

const router = useRouter();
const navStore = useNavigationStore();

const setBlock = (name) => {
  if(router.currentRoute.value.name !== "home") {
    router.push({ path: "/" });
  }

  navStore.setBlockClicked(true);
  navStore.setBlockIndex(name);

  setTimeout(() => {
    navStore.setBlockClicked(false);
    navStore.setBlockIndex("");
  }, 500);
}
</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__content">
        <p class="title" v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 0 }}">We'd be happy to hear from you!</p>
        <svg class="picture" xmlns="http://www.w3.org/2000/svg" width="761" height="427" viewBox="0 0 761 427" fill="none">
          <path d="M198 213.5C198 95.5872 293.587 0 411.5 0C529.413 0 625 95.5872 625 213.5C625 331.413 529.413 427 411.5 427C293.587 427 198 331.413 198 213.5Z" fill="white" fill-opacity="0.06"/>
          <path d="M571.971 423.971C583.538 425.911 595.421 426.921 607.539 426.921C725.43 426.921 821 331.351 821 213.46C821 95.5696 725.43 0 607.539 0C595.565 0 583.82 0.986023 572.383 2.88131C643.274 46.1251 690.601 124.186 690.601 213.3C690.601 302.588 643.09 380.778 571.971 423.971Z" fill="white" fill-opacity="0.06"/>
          <path d="M249.575 3.04245C178.686 46.2865 131.36 124.347 131.36 213.46C131.36 302.574 178.686 380.634 249.576 423.878C237.836 425.879 225.77 426.921 213.46 426.921C95.5694 426.921 0 331.351 0 213.46C0 95.5696 95.5694 0 213.46 0C225.77 0 237.836 1.04195 249.575 3.04245Z" fill="white" fill-opacity="0.06"/>
        </svg>
        <div class="info" ref="contacts" v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 40 }}">
          <p>Have questions or need more information?</p>
          <p>We're here to help! Reach out to us directly at moien@gerne.lu.</p>
        </div>

        <div class="main flex justify-between items-start">
          <div class="left flex flex-col">
            <Logo width="227" height="58" v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 0 }}" />
            <div class="nav flex items-center justify-between" v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 0 }}">
              <p @click="setBlock('about')">About</p>
              <p @click="setBlock('choose')">Why Choose Us?</p>
              <p @click="setBlock('benefits')">Benefits</p>
            </div>
          </div>
          <div class="contacts flex flex-col">
            <p class="contacts__title" v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 0 }}">Contacts</p>
            <p v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 0 }}">+352 20 331 755</p>
            <p v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 0 }}">Our Office: <br/>
              GERNE Technology SARL 17 rue Edmond Reuter, L-5326, Contern, Luxembourg
            </p>
            <a v-motion :initial="{ y: 10 }" :visibleOnce="{ y: 0, transition: { duration: 800, delay: 0 }}" href="mailto:moien@gerne.lu.">moien@gerne.lu.</a>
          </div>
        </div>
        <div class="bottom flex justify-between relative">
          <p class="desktop">© 2024 CRM Module by GERNE Technology. All Rights Reserved</p>
          <p class="mobile">©2024 GERNE Technology SARL. All Rights Reserved</p>
          <RouterLink to="/privacy">Privacy Policy</RouterLink>
          <GoTop />
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  background: linear-gradient(180deg, #0173EA 0%, #01274D 79%);
  position: relative;
  &__content {
    padding: 119px 0 30px;
  }
  .title {
    color: #FFF;
    font-feature-settings: 'salt' on;
    font-size: 88px;
    font-weight: 600;
    line-height: 124px;
    width: 100%;
    max-width: 943px;
  }
  .info {
    margin: 7px -37px 0 auto;
    max-width: 445px;
    width: max-content;
  }
  .info p {
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
  .picture {
    position: absolute;
    right: 0;
    top: 119px;
  }
  .main {
    margin-top: 64px;
    gap: 20px;
    .left {
      gap: 64px;
      width: 100%;
    }
  }
  .nav {
    width: 100%;
    max-width: 410px;
    font-size: 20px;
    line-height: 24px;
    p {
      color: #FFF;
      transition: all .3s ease;
      cursor: pointer;
      &:hover, &:active {
        color: #C6F608;
      }
    }
  }
  .contacts {
    color: #FFF;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    max-width: 411px;
    gap: 27px;
    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .bottom {
    color: #FFF;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.5;
    margin-top: 75px;
    .mobile,
    .gotop {
      display: none;
    }
    a {
      transition: all .3s ease;
      &:hover, &:active {
        color: #C6F608;
      }
    }
  }
  a, a:visited {
    color: #fff;
    text-decoration: none;
  }
}

@media (max-width: 900px) {
  .footer {
    .title {
      font-size: 70px;
      line-height: 90px;
    }
    .info {
      margin: 14px 0 100px;
      max-width: none;
      width: 100%;
    }
    .nav {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
}

@media (max-width: 700px) {
  .footer {
    &__content {
      padding: 40px 0 60px;
    }
    .title {
      font-size: 34px;
      line-height: 48px;
    }
    .info p {
      font-size: 16px;
      line-height: 30px;
    }
    .picture {
      right: auto;
      left: -50px;
      width: calc(100% + 50px);
      top: 20px;
    }
    .main {
      flex-direction: column;
      gap: 60px;
      margin-top: 0;
      .left {
        gap: 40px;
      }
    }
    .contacts {
      font-size: 15px;
      line-height: 26px;
    }
    .nav {
      font-size: 15px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.6px;
    }
    .bottom {
      flex-direction: column-reverse;
      gap: 83px;
      margin-top: 42px;
      opacity: 1;
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
        max-width: 280px;
        width: 80%;
      }
      .gotop {
        display: block;
        position: absolute;
        right: 20px;
        bottom: 35px;
      }
      a {
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        opacity: 0.9;
      }
      p {
        opacity: 0.5;
        font-size: 10px;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
}
</style>