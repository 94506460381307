<template>
  <div class="pill flex items-center justify-center">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.pill {
  border-radius: 51px;
  background: #025EB6;
  padding: 6px 21px;
  color: #FFF;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}

@media (max-width: 700px) {
  .pill {
    padding: 8px 16px;
    font-size: 13px;
    line-height: 20px;
  }
}
</style>