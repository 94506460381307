<script setup>
import { ref } from "vue";
import Pills from "@/components/additional/Pills.vue";
import Card from "@/components/additional/Card.vue";
import Line from "@/components/additional/Line.vue";

const pills = ref(['Campaign Design', 'A/B Testing', 'CJM', 'Automated Scripts for Promotions', 'Feedback Mechanism'])

const cards = ref([
  {
    title: "Campaign Design",
    title2: "",
    icon: "1.svg",
    description: "Easily create and automate targeted communication campaigns. Tailor your messages to diverse audience segments and ensure effective engagement",
  },
  {
    title: "A/B Testing",
    title2: "",
    icon: "2.svg",
    description: "Enhance your campaign performance with built-in A/B testing functionalities. Compare different versions of your campaigns to determine the most effective approach",
  },
  {
    title: "Customer Journey Mapping",
    title2: "",
    icon: "3.svg",
    description: "Visualize and understand the entire customer journey. Our tools help you track interactions from initial contact through conversion, allowing for more personalized and impactful campaigns\n",
  },
  {
    title: "Automated Script",
    title2: "for Promotions",
    icon: "4.svg",
    description: "Design automated scripts for promotions, mailings, and other marketing activities. Set conditions and mechanics for issuing bonuses and visualize the stages of each promotion",
  },
  {
    title: "Feedback Mechanism",
    title2: "",
    icon: "5.svg",
    description: "Collect real-time feedback from your campaigns to continually refine and optimize your strategies for better results\n",
  }
])
</script>

<template>
  <section class="benefits">
    <img class="bg" src="@/assets/img/bg2.jpg" alt="crm background" />
    <div class="container">
      <div class="benefits__content" ref="benefits">
        <p
          class="p-title"
          v-motion
          :initial="{ y: 20 }"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 0 }}"
        >Benefits</p>
        <p
          class="subtitle"
          v-motion
          :initial="{ y: 15 }"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 50 }}"
        >Transform with us the way you engage with your audience and achieve remarkable results</p>
        <div
          class="pills flex wrap justify-center items-center"
          v-motion
          :initial="{ opacity: 0, scale: 0.9 }"
          :visibleOnce="{opacity: 1, scale: 1, transition: { duration: 1000, delay: 0 }}"
        >
          <Pills
            v-for="(pill, index) in pills"
            :key="index"
          >{{ pill }}</Pills>
        </div>
        <div class="cards flex wrap">
          <Line rotate class="column border"/>
          <template
            v-for="(card, index) in cards"
            :key="index"
          >
            <Card
              :title="card.title"
              :description="card.description"
              :icon="card.icon"
              :title2="card.title2"
              v-motion
              :initial="{ opacity: 0 }"
              :visibleOnce="{opacity: 1, transition: { duration: 1200, delay: 0 }}"
            ></Card>
            <Line v-if="index%2===1" class="row border" />
          </template>
          <div class="last">
            <img
              src="@/assets/img/cards.png"
              alt="crm card"
              v-motion
              :initial="{ opacity: 0 }"
              :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: 0 }}"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.benefits {
  position: relative;
  padding-bottom: 154px;
  overflow: hidden;
  &__content {
    position: relative;
    z-index: 2;
    padding-top: 140px;
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .p-title {
    color: #124295;
    font-feature-settings: 'salt' on;
    font-size: 60px;
    font-weight: 600;
    line-height: 57px;
    text-transform: uppercase;
    text-align: center;
  }
  .subtitle {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    width: 100%;
    max-width: 630px;
    margin: 48px auto 40px;
  }
  .pills {
    border-radius: 44px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 1105px;
    padding: 8px 13px;
    margin: 0 auto;
    column-gap: 40px;
    row-gap: 10px;
  }
  .cards {
    position: relative;
    column-gap: 70px;
    row-gap: 0px;
    margin-top: 110px;
    padding: 60px 0;
    .card {
      width: 45%;
      &:nth-child(3),
      &:nth-child(6) {
        margin:0 26px 0 auto;
      }
      &:nth-child(5) {
        .title {
          white-space: nowrap;
        }
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8) {
        margin-top: 50px !important;
      }
    }
    .column {
      height: 100%;
      width: 60px;
      position: absolute;
      top: 0;
      left: 51%;
    }
    .last {
      width: 45%;
      margin: 30px auto 0;
      img {
        margin-left: 30px;
        max-width: 402px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .benefits {
    .pills {
      column-gap: 20px;
    }
    .cards{
      margin-top: 80px;
      .card:nth-child(3),
      .card:nth-child(6) {
        margin: 0 0 0 auto;
      }
      .card:nth-child(5) {
        .title {
          white-space: normal;
          text-align: left;
        }
      }
      .last {
        width: 40%;
        margin: 30px 0 0 -30px;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .benefits {
    .cards {
      flex-direction: column;
      .line {
        display: none;
      }
      .card {
        width: 100%;
        margin-left: 0 !important;
        min-height: 220px;
        margin-top: 40px !important;
        &:not(:nth-last-child(2)) {
          border-bottom: 1px solid #A7B9D8;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .benefits {
    padding-bottom: 90px;
    &__content {
      padding-top: 90px;
    }
    .p-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      text-transform: capitalize;
    }
    .subtitle {
      font-size: 16px;
      line-height: 30px;
      margin: 28px auto 26px;
    }
    .pills {
      column-gap: 20px;
      box-shadow: none;
      padding: 0;
      gap: 7px;
      background: none;
    }
    .cards {
      margin-top: 60px;
      padding: 0;
      .last {
        width: 100%;
        margin: 0;
        img {
          margin-left: -10px;
        }
      }
    }
  }
}
</style>